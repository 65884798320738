<template>
  <div>
    <div class="row aliases-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.ALIASES')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("ALIASES.NEW_ALIAS") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              v-if="canRemove"
              :disabled="aliasTable.selected.length == 0"
            >
              {{ $t("ALIASES.DEL_ALIAS") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
            </div>
            <v-data-table
              v-model="aliasTable.selected"
              :headers="headers"
              :items="aliasTable.aliases"
              :single-select="aliasTable.singleSelect"
              :search="search"
              show-select
              item-key="id"
              class="elevation-1"
              ref="aliasTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.listtype="{ item }">
                <span>{{ aliasTable.listType[item.listtype * 1 - 1] }}</span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="showItemInfor(item)"
                  v-b-tooltip.hover
                  v-if="canAdd"
                  :title="$t('COMMON.EDIT')"
                >
                  edit
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add alias modal------------- -->
            <b-modal
              id="addAlias"
              ref="addAlias"
              :title="$t('ALIASES.ADD_ALIAS')"
              size="lg"
            >
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="4">
                    <label
                      >{{ $t("ALIASES.ALIAS") }} @
                      {{ $t("COMMON.DOMAIN") }}</label
                    >
                  </b-col>
                  <b-col sm="8">
                    <b-row>
                      <b-col sm="4" class="groupFormUser">
                        <b-form-input
                          id="aliasName"
                          v-model="$v.addForm.aliasName.$model"
                          :state="validateState('aliasName')"
                          :placeholder="$t('ALIASES.ALIAS')"
                          @keydown.space.prevent
                          aria-describedby="input-aliasName-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-aliasName-feedback">
                          {{ $t("VALIDATION.ALIAS_FIELD") }}
                        </b-form-invalid-feedback>
                      </b-col>
                      <b-col sm="8" class="groupFormDomain">
                        <b-input-group prepend="@">
                          <b-form-select
                            v-model="addForm.domain.selected"
                            value-field="domain"
                            text-field="domain"
                            :options="addForm.domain.data"
                            disabled
                          ></b-form-select>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="4">
                    <label>{{ $t("ALIASES.FORWARDS_DESTINATION") }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.destination.selected"
                      value-field="value"
                      text-field="text"
                      :options="destinationData"
                      :disabled="externalOnly"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="addForm.destination.selected">
                  <b-col sm="4">
                    <label for="forwards">{{
                      $t("ALIASES.FORWARDS_TO")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-input
                      id="forwards"
                      v-model="$v.addForm.forwards.$model"
                      :state="validateState('forwards')"
                      :placeholder="$t('ALIASES.FORWARDS_TO')"
                      aria-describedby="input-forwards-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-forwards-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-else>
                  <b-col sm="4">
                    <label for="forwards">{{
                      $t("ALIASES.FORWARDS_TO")
                    }}</label>
                  </b-col>
                  <b-col sm="8">
                    <b-form-select
                      v-model="addForm.forwardsEmail.selected"
                      value-field="value"
                      text-field="text"
                      :options="addForm.forwardsEmail.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="createItem()">
                  {{ $t("ALIASES.ADD_ALIAS") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------edit alias modal start------------- -->
            <b-modal
              id="editAlias"
              ref="editAlias"
              :title="updateForm.editTitle"
            >
              <b-container fluid>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="editForwards">{{
                      $t("ALIASES.FORWARDS_TO")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="editForwards"
                      v-model="$v.addForm.forwards.$model"
                      :state="validateState('forwards')"
                      :placeholder="$t('ALIASES.FORWARDS_TO')"
                      aria-describedby="input-forwards-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-forwards-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button variant="outline-secondary" v-on:click="modalClose()">
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="updateAlias()">
                  {{ $t("ALIASES.UPDATE_ALIAS") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------edit alias modal end------------- -->

            <!-- ----------delete confirm modal------------- -->
            <!-- ----------- delete one alias confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_ITEM_WARNING", { item: delItem.alias }) }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete aliases confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required, email } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";

const usernameRegex = helpers.regex("alpha", /^[0-9a-zA-Z\-_.+:]+$/);

export default {
  mixins: [validationMixin],
  name: "aliases",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    externalOnly: 0,
    aliasTable: {
      singleSelect: false,
      aliases: [],
      selected: [],
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    addForm: {
      domain: {
        selected: "",
        data: [],
      },
      aliasName: "",
      forwards: "",
      destination: {
        selected: 0,
      },
      forwardsEmail: {
        selected: "",
        data: [],
      },
    },
    delItem: "",
    updateForm: {
      id: "",
      alias: "",
      domain: "",
      editTitle: "",
    },
  }),
  computed: {
    headers: function () {
      return [
        { text: this.$t("ALIASES.ALIAS"), value: "alias" },
        { text: this.$t("ALIASES.FORWARDS_TO"), value: "forward" },
        { text: this.$t("COMMON.ACTION"), value: "action", sortable: false },
      ];
    },
    destinationData: function () {
      return [
        { value: 0, text: this.$t("ALIASES.INTERNAL") },
        { value: 1, text: this.$t("ALIASES.EXTERNAL") },
      ];
    },
    canAdd: function () {
      return this.hasPermission("aliases", 5);
    },
    canRemove: function () {
      return this.hasPermission("aliases", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.addForm.domain.data = res.returndata;
        this.addForm.domain.selected = res.returndata[0].domain;
        this.getAliasesReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      aliasName: {
        required,
        usernameRegex,
      },
      forwards: {
        required,
        email,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.ALIASES") }]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    addformReset() {
      this.addForm.aliasName = "";
      this.addForm.forwards = "";
      this.addForm.domain.selected = this.addForm.domain.data[0].domain;
      this.addForm.forwardsEmail.selected = "";
      this.addForm.forwardsEmail.data = [];
    },
    /*  -----modal close------- */
    modalClose() {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.addformReset();
      this.$refs["addAlias"].hide();
      this.$refs["editAlias"].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getAliasesReqest(domain) {
      this.aliasTable.aliases = [];
      this.aliasTable.selected = [];
      postRequest({
        action: "getAllAliasesDomain",
        token: localStorage.id_token,
        domain: domain,
      }).then((res) => {
        if (res.returncode) {
          this.aliasTable.aliases = res.returndata;
        } else {
          this.aliasTable.aliases = [];
        }
      });
    },
    getAliasesForSelectedDomain(selectedDomain) {
      this.getAliasesReqest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      this.addForm.domain.selected = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "getAllUsersDomain",
        token: localStorage.id_token,
        domain: this.addForm.domain.selected,
        offset: 0,
        limit: 0,
        sorting: 0,
        slicing: 0,
        getOTPAndToken: 0,
        showQuota: 0,
      }).then((res) => {
        if (res.returncode) {
          this.addForm.forwardsEmail.selected = res.returndata[0].email;
          res.returndata.forEach((el) => {
            this.addForm.forwardsEmail.data.push({
              value: el.email,
              text: el.email,
            });
          });
          this.externalOnly = false;
          this.$refs["addAlias"].show();
        } else {
          this.externalOnly = true;
          this.addForm.destination.selected = 1;
          this.$refs["addAlias"].show();
        }
      });
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.aliasName.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;

      let forward = "";
      if (this.addForm.destination.selected) {
        forward = this.addForm.forwards;
      } else {
        forward = this.addForm.forwardsEmail.selected;
      }
      postRequest({
        action: "addAlias",
        token: localStorage.id_token,
        alias: this.addForm.aliasName,
        forward: forward,
        domain: selectedDomainForAdd,
      }).then((res) => {
        this.modalClose();
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getAliasesReqest(selectedDomainForAdd);
          this.makeToastVariant("success", res.returndata + '. ' + this.$t('COMMON.WAIT_MESSAGE'));
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addformReset();
      });
    },
    /* ------create item end---- */
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(aid) {
      postRequest({
        action: "removeAlias",
        token: localStorage.id_token,
        aid: aid,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
          for (let i = 0; i < this.aliasTable.aliases.length; i++) {
            if (this.aliasTable.aliases[i].id == aid) {
              this.aliasTable.aliases.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.id);
    },
    deleteItems() {
      if (this.aliasTable.selected.length > 0) {
        this.aliasTable.selected.forEach((el) => {
          this.deleteRequest(el.id);
        });
      }
    },

    /* ------edit item----- */
    showItemInfor(item) {
      this.updateForm.id = item.id;
      postRequest({
        action: "getAliasFromId",
        token: localStorage.id_token,
        aid: item.id,
      }).then((res) => {
        if (res.returncode) {
          this.updateForm.editTitle =
            this.$t("COMMON.UPDATE") +
            res.returndata.alias +
            "@" +
            res.returndata.domain;
          this.addForm.forwards = res.returndata.forward;
          this.updateForm.alias = res.returndata.alias;
          this.updateForm.domain = res.returndata.domain;
          this.$refs["editAlias"].show();
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    updateAlias() {
      const currentDomain = this.updateForm.domain;
      postRequest({
        action: "updateAlias",
        token: localStorage.id_token,
        editdomain: currentDomain,
        editalias: this.updateForm.alias,
        editforward: this.addForm.forwards,
      }).then((res) => {
        if (res.returncode) {
          this.getAliasesReqest(currentDomain);
          this.makeToastVariant("success", res.returndata+ '. ' + this.$t('COMMON.WAIT_MESSAGE'));
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.modalClose();
      });
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getAliasesForSelectedDomain(newValue.domain);
    },
  },
};
</script>
